<template>
  <div class="box"> 
    <ul>
        <li>姓名：</li>
        <li>{{ params.name }}</li>
    </ul>
    <ul>
        <li>公司：</li>
        <li>{{ params.companyName }}</li>
    </ul>
    <ul>
        <li>职位：</li>
        <li>{{ params.position }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
        params:'',
    }
  },
  created() {
    this.params = this.$route.query
  },
  mouted() {
    console.log(this.$router.query)
  },
  methods: {

  },
}
</script>
<style scoped>
.box{
    box-sizing: border-box;
    padding: 5px;
    padding-left: 10px;
}
ul{
    display: flex;
    margin-bottom: 4px;
    font-size: 12px;
}
li{
    /* min-width: 40px; */
}
ul>li:nth-of-type(2){
    width: 160px;
}
</style>